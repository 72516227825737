import { Injectable, inject } from '@angular/core';
import { Observable } from '@apollo/client';
import {
  CreateLocationGQL,
  GetProfileLocationsQuery,
  Maybe,
  GetProfileLocationsDocument,
  UpdateLocationGQL,
  DeleteLocationInput,
  DeleteLocationGQL,
  CreateLocationInput,
  GetProfileLocationsGQL,
  Location,
} from '@designage/gql';
import { ILocationForm } from '@desquare/interfaces';
import { CurrentUserService } from '@desquare/services';
import { GraphQLFormattedError } from 'graphql';
import { map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LocationService {
  constructor(
    private createLocationGQL: CreateLocationGQL,
    private updateLocationGQL: UpdateLocationGQL,
    private deleteLocationGQL: DeleteLocationGQL
  ) {}

  private getProfileLocationsGql$ = inject(GetProfileLocationsGQL).watch(
    {
      profileId: inject(CurrentUserService).getCurrentProfileId(),
    },
    {
      fetchPolicy: 'network-only',
    }
  );

  getProfileLocationsFromApi$() {
    return this.getProfileLocationsGql$.valueChanges.pipe(
      map(({ data, loading, errors }) => {
        const locations: Location[] = data.profile?.locations as Location[];
        return { locations, loading, errors };
      })
    );
  }

  refreshLocationsFromApi() {
    this.getProfileLocationsGql$.refetch();
  }

  // .subscribe({
  //   next: ({ data, loading }) => {
  //     this.loading = loading;
  //     const { profile } = data;

  //     if (profile) {
  //       this.locations = profile.locations;
  //       this.locationNames = this.locations.map((x) => x.name);
  //       this.selectedLocation = this.locations.find(
  //         (x) => x.id === this.selectedLocationId
  //       );
  //       if (this.selectedLocation) {
  //         this.locationForm.patchValue({
  //           locationId: this.selectedLocation.id,
  //           locationName: this.selectedLocation.name,
  //         });
  //         this.selectionChange.emit(this.selectedLocation);
  //       }
  //     }
  //   },
  //   error: (error: ApolloError) => {
  //     error.graphQLErrors.forEach((gqlError) => {
  //       console.error('getProfileLocations', gqlError);
  //       this.toasterService.handleGqlError(gqlError);
  //     });

  //     this.loading = false;
  //   },
  // });

  createLocation(input: ILocationForm, profileId: Maybe<string>) {
    return this.createLocationGQL.mutate(
      { input },
      {
        update: (proxy, result) => {
          // try catch to prevent error thrown when cache has no profile.locations
          try {
            const data = proxy.readQuery<GetProfileLocationsQuery>({
              query: GetProfileLocationsDocument,
              variables: { profileId },
            });
            if (
              data?.profile?.locations &&
              result.data?.createLocation?.location
            ) {
              data.profile.locations.push(result.data.createLocation.location);
              data.profile.locations.sort((a, b) =>
                a.name && b.name && a.name > b.name ? 1 : -1
              );
              proxy.writeQuery({
                query: GetProfileLocationsDocument,
                variables: { profileId },
                data,
              });
            }
          } catch (error) {
            // do nothing
          }
        },
      }
    );
  }

  updateLocation(input: ILocationForm, profileId: Maybe<string>) {
    return this.updateLocationGQL.mutate(
      {
        input,
      },
      {
        update: (proxy, result) => {
          // try catch to prevent error thrown when cache has no profile.locations
          try {
            const data = proxy.readQuery<GetProfileLocationsQuery>({
              query: GetProfileLocationsDocument,
              variables: { profileId },
            });
            const updatedLocation = result?.data?.updateLocation?.location;
            if (data?.profile?.locations && updatedLocation?.id) {
              let locationToUpdate = data.profile.locations.find(
                (x) => x.id === updatedLocation.id
              );
              if (locationToUpdate) {
                locationToUpdate = updatedLocation;
                data.profile.locations.sort((a, b) =>
                  a.name && b.name && a.name > b.name ? 1 : -1
                );
                proxy.writeQuery({
                  query: GetProfileLocationsDocument,
                  variables: { profileId },
                  data,
                });
              }
            }
          } catch (error) {
            // do nothing
          }
        },
      }
    );
  }

  deleteLocation(input: DeleteLocationInput, profileId: Maybe<string>) {
    return this.deleteLocationGQL.mutate(
      {
        input,
      },
      {
        update: (proxy) => {
          // try catch to prevent error thrown when cache has no profile.locations
          try {
            const data = proxy.readQuery<GetProfileLocationsQuery>({
              query: GetProfileLocationsDocument,
              variables: { profileId },
            });
            if (data?.profile?.locations) {
              const locationToRemove = data.profile.locations.find(
                (x) => x.id === input.id
              );
              if (locationToRemove) {
                data.profile.locations.splice(
                  data.profile.locations.indexOf(locationToRemove),
                  1
                );
                proxy.writeQuery({
                  query: GetProfileLocationsDocument,
                  variables: { profileId },
                  data,
                });
              }
            }
          } catch (error) {
            // do nothing
          }
        },
      }
    );
  }

  toCreateLocationInput(input: ILocationForm): CreateLocationInput {
    const createLocationInput: CreateLocationInput = {
      name: input.name,
      city: input.city,
      coordinates: input.coordinates,
      country: input.country,
      phoneNumber: input.phoneNumber,
      profileId: input.profileId,
      region: input.region,
      streetAddress1: input.streetAddress1,
      streetAddress2: input.streetAddress2,
      zip: input.zip,
    };

    // console.log('input: ILocationForm: ', input); // DEBUG
    // console.log(
    //   'createLocationInput: CreateLocationInput: ',
    //   createLocationInput
    // ); // DEBUG

    return createLocationInput;
  }
}
